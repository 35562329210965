<template>
  <div class="home">

    <ph-navigation />

    <ph-header />

    <div class="keypoints">

      <div class="keypoint">
        <strong class="keypoint__value">4x</strong>
        <p class="keypoint__description">Faster to train AR in  the classroom</p>
      </div>

      <div class="keypoint">
        <strong class="keypoint__value">2.75x</strong>
        <p class="keypoint__description">More confindent to apply skills learned after training</p>
      </div>

      <div class="keypoint">
        <strong class="keypoint__value">3.75x</strong>
        <p class="keypoint__description">More emotionally connected to content than classroom learners</p>
      </div>


      <div class="keypoint">
        <strong class="keypoint__value">4x</strong>
        <p class="keypoint__description">More focused than their e-learning peers</p>
      </div>

    </div>

    <div class="showcase showcase--reversed">

      <div class="showcase__content">
        <h2>Raise cyber awareness in the most <span class="underline">immersive way</span></h2>
        <p>Our unique approach to employee training comes from combining our cyber security expertise with the upcoming mixed reality.</p>
        <p>The approach allows us to immerse your employees to the unforseen level of reality, right in front of their office desk.</p>
      </div>
      <div class="showcase__visual">
        <!-- <div style="padding:56% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/777268612?h=f66c40a7c4&amp;badge=0&amp;loop=1;autoplay=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="AR training"></iframe></div> -->
        <img src="@/assets/images/HeadsetAR7.png" alt="">
      </div>

    </div>

    <div class="benefits">

      <h2 class="benefits__title"><span class="underline">Augmented reasoning</span></h2>
      <div class="benefits__list">

        <div class="benefit">

          <h3>Employee personalisation</h3>
          <p>To increase the memorability of simulated attacks, we use Dynamic script synthetisation. Your office employee names end up in our voiceovers.</p>

        </div>
        <div class="benefit">

          <h3>Real life scenarios</h3>
          <p>Educational scenarios are based on the most common workplace mistakes that compromise entire companies.</p>

        </div>
        <div class="benefit">

          <h3>Tailored experiences</h3>
          <p>Admin interface allows you to pick and choose various attack vectors you want to educate your employees. It’s a simple click away.</p>

        </div>
        <div class="benefit">

          <h3>Smart reporting</h3>
          <p>Review employee training scores and receive aggregated resilience scoring to detect the weakest points in your security posture early on.</p>

        </div>
      </div>

    </div>

    <div class="partners">
      <span>Our partners and clients</span>
      <img src="@/assets/images/partners.png" alt="partners">
    </div>

    <div class="showcase">

      <div class="showcase__visual">
        <img src="@/assets/images/VisualPlaceholder.png" alt="">
      </div>
      <div class="showcase__content">
        <h2><span class="underline">Prevent</span> cyber attacks</h2>
        <p>Using immersive technologies for cyber security training allows your employees to experience threats in the most immersive and memorable way.</p>
        <p>Embrace the power of immersive technologies today to ensure no threats slip through your human firewall.</p>
      </div>

    </div>

    <div class="altbg">

    </div>

    <subscribe />

    <blog />

    <ph-footer />


  </div>
</template>
<script>
import Subscribe from '@/components/Subscribe';
import PhHeader from '@/components/Header';
import PhFooter from '@/components/Footer';
import PhNavigation from '@/components/Navigation';
import Blog from '@/components/Blog';
export default {
  components: {
    Subscribe,
    Blog,
    PhHeader,
    PhFooter,
    PhNavigation
  }
}
</script>
