<template>
  <div class="blog">

    <ph-navigation />

    <header class="header header--small">

      <div>
        <h1>{{ post.title }}</h1>
        <div class="header__subtitle">{{ post.meta.category }} &middot; By {{ post.meta.author }}</div>
      </div>

      <!-- <img :src="post.content.image" alt=""> -->

    </header>

    <div class="blog__content" v-html="post.content.full"></div>

    <blog />

    <ph-footer />

  </div>

</template>
<script>
import posts from '@/store/index.js'
import PhFooter from '@/components/Footer'
import PhNavigation from '@/components/Navigation'
import Blog from '@/components/Blog'
export default {
  components: {
    Blog,
    PhFooter,
    PhNavigation
  },
  data() {
    return {
      posts
    }
  },
  computed: {
    post() {
      return this.posts.find(post => post.id == parseInt(this.$route.params.id)) || {}
    }
  }
}
</script>
