<template>
  <div class="home">

    <ph-navigation />

    <header class="header header--small">

      <div>
        <h1>About us</h1>
      </div>

    </header>

    <div class="about">

      <div class="about__content">
        <h2>PhishAR</h2>
        <p>PhishAR was founded in 2019 by dr.sc. Ivo Sluganovic and prof.dr.sc. Ivan Martinovic from the University of Oxford. Founded as an University of Oxford spinnout, the company is closely connected to the University.</p>
        <p>From the get go, the company is focused on the immersive aspects of augmented/mixed reality, and applying those capabilities in the fields of cybersecurity and education.</p>
      </div>
      <div class="about__media about__media--mobile">
        <img src="@/assets/images/photos/850_4259.jpg" alt="">
      </div>

    </div>

    <div class="about">

      <div class="about__media">
        <img src="@/assets/images/LokotVisual.png" alt="">
      </div>
      <div class="about__content">
        <h2>Lokot companion app</h2>
        <p>Lokot is our in-house built second-factor authenticator and security due diligence mobile app.</p>
        <p>Based on our proprietary IP, Lokot uses computer vision and physical world context to understand highly sophisticed cyberattacks.</p>
        <p>Only if the website is deemed secure, Lokot will provide a TOTP code to enter the website, making it the only real-time phishing proof authenticator.</p>
        <p><br><a href="https://lokot.app" target="_blank" class="cta">&rarr; Visit Lokot website</a></p>
      </div>

    </div>

    <div class="about">

      <div class="about__content">
        <h2>Let's talk</h2>
        <p>Whether you're a potential job candidate wanting to work with us, or an anaylist looking to better understand our product &mdash; our emails are open looking forward to your message.</p>
        <p>Please contact appropriate email address to receive a reply quickly.</p>
        <ul>
          <li><a href="#">investors@phishar.com</a></li>
          <li><a href="#">careers@phishar.com</a></li>
        </ul>
      </div>
      <div class="about__media about__media--mobile">
        <img src="@/assets/images/photos/850_4334.jpg" alt="">
      </div>

    </div>

    <blog />

    <ph-footer />

  </div>

</template>
<script>
import PhNavigation from '@/components/Navigation'
import PhFooter from '@/components/Footer'
import Blog from '@/components/Blog'
export default {
  components: {
    Blog,
    PhNavigation,
    PhFooter
  }
}
</script>
