<template>

  <div :class="{ 'altbg': altBg }">

    <div class="blogcase">

      <div class="post" v-for="post in posts" :key="post.id">
        <h3>{{ post.title }}</h3>
        <div class="post__image">
          <img :src="post.content.image">
        </div>
        <p>{{ post.content.lede }}</p>
        <router-link :to="{ name: 'blog', params: { id: post.id, slug: post.meta.slug } }">Read more &raquo;</router-link>
      </div>

    </div>

  </div>

</template>
<script>
import posts from '@/store/index.js'
export default {
  props: {
    altBg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      posts
    }
  }
}
</script>
