
const posts = [
  {
    id: 1,
    title: "3D genome visualisation in AR",
    meta: {
      slug: "3d-genome-visualisation-in-ar",
      description: "",
      date: "",
      category: "Showcase",
      author: "Alice",
    },
    content: {
      image: "https://images.unsplash.com/photo-1592478411213-6153e4ebc07d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1412&q=80",
      lede: "Find out how we collaborated with the Univeristy of Oxford on building the metaverse experience for bio studies.",
      full: `<p>3D visualisation of human organs using augmented reality (AR) is a breakthrough technology that is revolutionizing the way we understand and interact with our own biology. Developed by the team at PhishAR, this groundbreaking project was recently delivered to the University of Oxford, where it is being used to study the human genome in a whole new way.</p>
      <p>One of the key benefits of presenting the genome in a physical environment is that it allows us to see and understand the inner workings of our bodies in a much more intuitive and engaging way. By using AR technology, PhishAR has been able to create a highly immersive and interactive experience that allows researchers and students alike to explore the complex structure of human organs in real time. This not only makes the study of the genome more engaging and accessible, but it also has the potential to unlock new insights and breakthroughs in the field of medical research.</p>
      <p>Another major advantage of using AR to visualise human organs is that it allows us to see the body in a much more dynamic and interactive way. With traditional methods of studying the genome, researchers are limited to static images and diagrams that can be difficult to interpret and understand. By using AR, PhishAR has been able to create a highly interactive experience that allows users to manipulate and explore the body in real time. This not only makes the study of the genome more engaging and intuitive, but it also has the potential to uncover new patterns and relationships that would be impossible to detect using traditional methods.</p>
      <p>Overall, the 3D visualisation of human organs using augmented reality is a truly innovative and groundbreaking project that has the potential to revolutionize the way we study and understand our own biology. With its powerful combination of immersive technology and interactive design, PhishAR has created a tool that is not only engaging and accessible, but also has the potential to unlock new insights and breakthroughs in the field of medical research. As the project continues to evolve and develop, we can only imagine the exciting possibilities that are yet to come.</p>`
    }
  },
  {
    id: 2,
    title: "AR predictions for 2023",
    meta: {
      slug: "ar-predictions-for-2023",
      description: "",
      date: "",
      category: "Showcase",
      author: "Alice",
    },
    content: {
      image: "https://images.unsplash.com/photo-1617802690992-15d93263d3a9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
      lede: "Read about what we expect to see in the world of MR in the upcoming 2023.",
      full: `<p>The world of mixed reality is rapidly evolving, and there is a lot of excitement and anticipation surrounding what we can expect to see in the next year. With companies like PhishAR leading the charge, the future of augmented and mixed reality looks bright and full of possibilities. Here are just a few of the exciting developments we can expect to see in the world of mixed reality in 2023.</p>
      <p>One of the biggest trends we expect to see in the world of mixed reality in the coming year is the continued growth and expansion of the technology. As more and more companies and organizations begin to embrace the power of augmented and mixed reality, we can expect to see an explosion of new applications and uses for the technology. From virtual reality shopping experiences to interactive training simulations, the possibilities for mixed reality are nearly endless, and we can't wait to see what new innovations are on the horizon.</p>
      <p>Another major trend we expect to see in the world of mixed reality in 2023 is the continued integration of the technology into our daily lives. As mixed reality becomes more accessible and affordable, we can expect to see it increasingly used in a wide variety of applications, from gaming and entertainment to education and training. With companies like PhishAR leading the way, we can expect to see a surge of new mixed reality experiences that will change the way we live and interact with the world around us.</p>
      <p>One of the key benefits of augmented and mixed reality technology is its ability to enhance and enrich our lives in a variety of ways. Whether it's through immersive gaming experiences or more practical applications like remote training and collaboration, mixed reality has the potential to make our lives easier, more efficient, and more enjoyable. As the technology continues to evolve and develop, we can expect to see more and more innovative uses for mixed reality that will improve our daily lives in ways we never thought possible.</p>
      <p>In conclusion, the future of mixed reality is looking bright, and we are excited to see what the next year has in store. With companies like PhishAR leading the charge, we can expect to see a surge of new innovations and applications that will revolutionize the way we live and interact with the world around us. From enhanced gaming and entertainment experiences to practical applications that make our lives easier and more efficient, the future of mixed reality is full of possibilities, and we can't wait to see what comes next.</p>`
    }
  },
  {
    id: 3,
    title: "Using screens for content tracking",
    meta: {
      slug: "using-screens-for-content-tracking",
      description: "",
      date: "",
      category: "Showcase",
      author: "Alice",
    },
    content: {
      image: "https://images.unsplash.com/photo-1606406334188-65c994a55227?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2948&q=80",
      lede: "Our Machine learning team built a new way to immerse your MR content into computer screens. Here's how.",
      full: `<p>3D screen tracking and content understanding are key factors for the next generation of augmented reality (AR) applications. These technologies allow AR apps to accurately track and understand the user's environment, which is essential for creating immersive and engaging AR experiences. At PhishAR, our machine learning team has been working on a screen tracking software development kit (SDK) that will allow third-party developers to easily incorporate advanced 3D screen tracking and content understanding into their AR apps.</p>
      <p>One of the biggest challenges facing AR developers is creating apps that can accurately track and understand the user's environment. Without this capability, AR experiences can be disorienting and difficult to use, which can lead to user frustration and a poor overall experience. That's where our screen tracking SDK comes in. By incorporating advanced machine learning algorithms and 3D tracking technology, our SDK allows AR apps to accurately understand the user's environment and provide a seamless and intuitive AR experience.</p>
      <p>Another key benefit of our screen tracking SDK is that it allows third-party developers to easily incorporate advanced 3D tracking and content understanding into their own AR apps. With our SDK, developers can quickly and easily add these capabilities to their apps, which can greatly enhance the user experience and make their apps more engaging and immersive. This is especially important as the AR market continues to grow and evolve, and developers are looking for ways to differentiate their apps and create truly unique and engaging AR experiences.</p>
      <p>In conclusion, 3D screen tracking and content understanding are critical for the next generation of AR applications. With our machine learning team's screen tracking SDK, third-party developers can easily incorporate these advanced capabilities into their own AR apps, which will greatly enhance the user experience and make their apps more engaging and immersive. As the AR market continues to grow and evolve, we are excited to see how developers will use our screen tracking SDK to create truly unique and engaging AR experiences.</p>`
    }
  },
]

export default posts
