<template>
  <div class="navigation">

    <router-link :to="{ name: 'home' }" class="navigation__logo">
      <img src="@/assets/images/phishar_logo.svg" alt="">
    </router-link>
    <div class="navigation__toggle" :class="{ 'navigation__toggle--inline': !menuOpen }" @click="toggle">
      <img src="@/assets/images/icon-burger.svg" alt="close" v-if="!menuOpen">
      <img src="@/assets/images/icon-close.svg" alt="close" v-else>
    </div>
    <nav class="navigation__list" :class="{ 'navigation__list--visible': menuOpen }">
      <ul>
        <li><router-link :to="{ name: 'home' }" :class="{ 'active': $route.name === 'home' }">XR Immersive training</router-link></li>
        <li><a href="https://lokot.app" target="_blank">Companion app</a></li>
        <li><router-link :to="{ name: 'about' }" :class="{ 'active': $route.name === 'about' }">About</router-link></a></li>
        <li class="onMobile"><a href="#getstarted" class="accent">Get access</a></li>
        <li>
          <a href="https://app.phishar.com" target="_blank" title="Launch AR platform">
            <img src="@/assets/images/icon-enter.svg" alt="enter"> Launch
          </a>
        </li>
      </ul>
    </nav>

  </div>
</template>
<script>
export default {
  data() {
    return {
      menuOpen: false
    }
  },
  methods: {
    toggle() {
      this.menuOpen = !this.menuOpen;
    }
  }
}
</script>
